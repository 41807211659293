import React, { useEffect } from 'react';
import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import design from '../config/design';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../components/Title/Title';
import Carousel from '../components/Carousel/Carousel';
import { View } from '../components/View/View';
import Text from '../components/Text/Text';
import Button from '../components/Button/Button';
import { Visible } from '../components/Visible/Visible';
import HeroImage from '../components/HeroImage/HeroImage';
import ProductCardList from '../components/ProductCard/ProductCardList';
import ProductCardListCampaign from '../components/ProductCard/ProductCardListCampaign';
import { BigView } from '../components/BigView/BigView';

function Products() {
  const {
    productsInCampaign,
    nationalProducts,
    otherProducts,
    productsConfig,
  } = useStaticQuery(
    graphql`
      query {
        productsInCampaign: allDatoCmsProduct(
          filter: { isActiveInCampaign: { eq: true } }
        ) {
          nodes {
            productName
            productImage {
              alt
              url
            }
            shortDescription
            isActiveInCampaign
            productUrl
          }
        }
        nationalProducts: allDatoCmsProduct(
          filter: { isActiveInNationalProducts: { eq: true } }
        ) {
          nodes {
            productName
            productImage {
              alt
              url
            }
            shortDescription
            productUrl
          }
        }
        otherProducts: allDatoCmsProduct(
          filter: { isActiveInOtherProducts: { eq: true } }
        ) {
          nodes {
            productName
            productImage {
              alt
              url
            }
            shortDescription
            productUrl
          }
        }
        productsConfig: allDatoCmsProductConfig {
          edges {
            node {
              subtitleSectionCampaign
              subtitleSectionNationalProducts
              subtitleSectionOtherProducts
              titleSectionCampaign
              titleSectionNationalProducts
              titleSectionOtherProducts
              textButton
              textButtonUrl
              productBannerCampaign {
                bannerImage {
                  url
                }
                linkCampaignUrl
                textImage
              }
            }
          }
        }
      }
    `
  );

  const contentText = productsConfig.edges[0].node;

  const [campaignProducts, setCampaignProducts] = React.useState(
    productsInCampaign.nodes
  );
  const [otherProductsLoad, setOtherProducts] = React.useState(
    otherProducts.nodes
  );

  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {isClient && (
        <LayoutWrapper>
          <BigView>
            <View
              desktopLeft={6}
              desktopRight={6}
              mobileLeft={1}
              mobileRight={1}
              tabletLeft={3}
              tabletRight={3}
              bottom={1}
            >
              <View top={3.5} bottom={3.5}>
                {/* <Visible desktop> */}
                <Carousel content={contentText.productBannerCampaign} />
                {/* </Visible> */}
                {/* <Visible tablet mobile>
                <HeroImage
                  disableLink
                  inCarousel
                  src={contentText.productBannerCampaign[0].bannerImage.url}
                  content={contentText.productBannerCampaign[0].textImage}
                  url={contentText.productBannerCampaign[0].linkCampaignUrl}
                />
              </Visible> */}
              </View>
              <Title
                color={design.colors.blue150}
                content={contentText.titleSectionCampaign}
              />
              <Text
                color={design.colors.dark}
                content={contentText.subtitleSectionCampaign}
              />
              <View top={2} />
              <ProductCardListCampaign items={campaignProducts} />
              <View top={5} />
              <Title
                color={design.colors.blue150}
                content={contentText.titleSectionOtherProducts}
              />
              <Text
                color={design.colors.dark}
                content={contentText.subtitleSectionOtherProducts}
              />
              <View top={3.5} />
              <ProductCardList items={otherProductsLoad} />
              <View top={5} />
              <Title
                color={design.colors.blue150}
                content={contentText.titleSectionNationalProducts}
              />
              <Text
                color={design.colors.dark}
                content={contentText.subtitleSectionNationalProducts}
              />
              <View top={3.5} />
              <ProductCardList items={nationalProducts.nodes} />
            </View>
          </BigView>
          <Visible mobile>
            <View left={1} right={1}>
              <Button
                content={contentText.textButton}
                href={contentText.textButtonUrl}
                bgColor="primaryOrange"
                type="rounded"
                width="block"
              />
            </View>
          </Visible>
          <Visible desktop>
            <View center>
              <Button
                content={contentText.textButton}
                href={contentText.textButtonUrl}
                bgColor="primaryOrange"
                type="rounded"
                width="block"
              />
            </View>
          </Visible>
        </LayoutWrapper>
      )}
    </>
  );
}

export default Products;
