import React from 'react';
import ProductCardCampaign from './ProductCardCampaign';
import { GridContainer } from '../Grid/Grid';

function ProductCardListCampaign(props) {
  return (
    <GridContainer>
      {props.items.map(item => (
        <>
          <ProductCardCampaign
            title={item.productName}
            img={item.productImage.url}
            shortDescription={item.shortDescription}
            productUrl={item.productUrl}
          />
        </>
      ))}
    </GridContainer>
  );
}

export default ProductCardListCampaign;
