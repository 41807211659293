import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import { View } from '../View/View';
import ReactMarkdownMain, * as ReactMarkdownAlt from 'react-markdown/with-html';
import { Link as StyledLink } from 'gatsby';

const ReactMarkdown = ReactMarkdownMain || ReactMarkdownAlt;

const Link = styled(StyledLink)`
  text-decoration: none;
`;

const StyledProductCard = styled.div`
  /* // THE CARD */
  .card {
    cursor: pointer;
    background: #f7f7f7;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3), 0 0px 0px rgba(0, 0, 0, 0.22);
    border-radius: 10px;
    overflow: hidden;
    margin-top: 1rem;
    background-color: ${design.colors.white};
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      /* width: 150px; */
      height: 350px;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      width: 250px;
      height: 350px;
    }
  }

  .card-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 14px;
    color: ${design.colors.primaryBlue};
    width: 100%;
  }

  .card-intro {
    margin-top: 0.5rem;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: ${design.colors.primaryBlue};
  }
`;

const CampaignActive = styled.div`
  background-color: ${props => props.bgColor};
  border-radius: 2px 5px 2px 2px;
  width: 85px;
  height: 25px;
  margin-left: auto;

  span {
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: auto;
    letter-spacing: 0.1em;
    padding-top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${design.colors.white};
  }
`;
const Campaign = styled.div`
  min-width: 100%;
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  height: 141px;
`;

const ProductCard = props => {
  return (
    <StyledProductCard>
      <Link to={`${props.productUrl}`}>
        <div className="card">
          <View left={1} right={1} top={1}>
            <div>
              <div>
                <Campaign src={props.img}>
                  <CampaignActive bgColor={design.colors.primaryOrange}>
                    <span>Em campanha</span>
                  </CampaignActive>
                </Campaign>
              </div>
            </div>
            <View top={1} />
            <h2 className="card-title">{props.title}</h2>
            <p className="card-intro">
              {/* max characteres: 110 */}
              <ReactMarkdown
                source={props.shortDescription}
                escapeHtml={false}
              />
            </p>
          </View>
        </div>
      </Link>
    </StyledProductCard>
  );
};
export default ProductCard;
